// TODO: This file is not being used in App.tsx

import {
  AwsGenerationInput,
  AwsGenerationOutput,
  AwsService,
  ConstructType,
  OutputFormat,
  UseCase
} from '@amzn/alkimia-model';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Alert,
  Box,
  Button,
  Container,
  ExpandableSection,
  FormField,
  Header,
  Input,
  Pagination,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react-v3';
import {
  OptionDefinition,
} from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userSelection } from '../../api/calls/userSelection';
import { displayErrorMessage, getStage } from '../../utils';
import { CONSTRUCT_OPTIONS, LIST_OF_REGIONS, RELEVANT_OPTIONS } from '../../utils/Constants';
import { AwsRumClient } from '../../utils/awsRum';
import { FEATURE_USE_CASE_SELECTION } from '../../utils/features';
import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  DEFAULT_TABLE_ITEM,
  Preferences,
  SEARCHABLE_COLUMNS,
  TableHeader,
} from './tableConfig';

type AlkimiaResourceSelectionProps = {
  token: CognitoToken,
  accountLabel: string,
  regionID: string,
  listResources: AwsService[],
  errorMessages: string[],
  setAwsGenerationOutput: React.Dispatch<React.SetStateAction<AwsGenerationOutput>>,
}

type ItemType = {
  name: string,
  selected: boolean,
  type: string,
  service: string,
  supportedConstruct: ConstructType,
  relevant: boolean,
  clusterId: string,
}

type EmptyStateProps = {
  title: string,
  subtitle: string,
  action: any,
}

type SelectedOption = {
  label: string;
  value: string;
}

type ConstructOption = {
  label: string;
  value: ConstructType;
}
const defaultConstruct = CONSTRUCT_OPTIONS[0];
const defaultRelevantOption = RELEVANT_OPTIONS[0];

const useCaseOptions: SelectedOption[] = [
  { label: 'Use CDK to deploy to new region', value: 'create' },
  { label: 'Bring selected under CDK management', value: 'represent' },
];

/**
 * This is the selection page component where users may select which resources they would like to
 * to generate CDK code
 * @param token user token to call generateAwsIaC api after user has selected their resources
 * @param accountLabel Account information, will be used to parse and get TargetAccountId
 * @param regionID Region Selected by customer from Home page
 * @param listResources List of Resources after selecting an aws account
 * @param errorMessages Any error messages when listing/describing resources
 * @function setAwsGenerationOutput sets global state of generated output so generation page can display
 * @returns Selection page component
 */
function AlkimiaResourceSelectionComponent({
  token,
  accountLabel,
  regionID,
  listResources,
  errorMessages,
  setAwsGenerationOutput,
}: AlkimiaResourceSelectionProps) {
  const history = useHistory();
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockMsg, setBlockMsg] = useState('');
  const [selectedUseCase, setSelectedUseCase] = useState<SelectedOption>(useCaseOptions[0]);
  const [targetDisbale, setTargetDisable] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [allItems, setAllItems] = useState([DEFAULT_TABLE_ITEM]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [targetAccountID, settargetAccountID] = useState(accountLabel.split(':')[0]);
  const [targetRegion, settargetRegion] = useState('us-east-1');
  const [regionOptions, setregionOptions] = useState<SelectedOption[]>(LIST_OF_REGIONS);
  const [selectedTargetRegion, setselectedTargetRegion] = useState<SelectedOption>({
    label: 'us-east-1 (N.Virginia)',
    value: 'us-east-1',
  });
  const [constructType, setconstructType] = useState(ConstructType.DEFAULT);

  const [construct, setConstruct] = useState('0');
  const [selectedFilterConstruct, setSelectedFilterConstruct] = useState<SelectedOption>(CONSTRUCT_OPTIONS[0]);
  const [constructOptions, setConstructOptions] = useState<SelectedOption[]>(CONSTRUCT_OPTIONS);

  const [relevant, setRelevant] = useState('0');
  const [selectedRelevantOption, setSelectedRelevantOption] = useState<SelectedOption>(RELEVANT_OPTIONS[0]);
  const [relevantOptions, setRelevantOptions] = useState<SelectedOption[]>(RELEVANT_OPTIONS);

  const processCurrentResource = (funcUsedToProcess) => listResources.forEach((currentService) => {
    currentService.types.forEach((currentType) => {
      currentType.resources.forEach((currentResource) => funcUsedToProcess(currentService, currentType, currentResource));
    });
  });

  const disableTargetOptionsForImport = (selectedOption: OptionDefinition) => {
    setTargetDisable(selectedOption.value === 'represent');
  };

  const getItems = () => {
    const itemList: ItemType[] = [];
    const myFunc = (currentService, currentType, currentResource) => {
      const selectedPropertyExist = Object.prototype.hasOwnProperty.call(currentResource, 'selected');
      let resourceSelection = false;
      if (selectedPropertyExist) {
        resourceSelection = currentResource.selected!;
      }
      itemList.push({
        name: currentResource.name,
        selected: resourceSelection,
        type: currentType.type,
        service: currentService.service,
        supportedConstruct: currentResource.supportedConstructType,
        relevant: currentResource.relevant,
        clusterId: (currentResource.clusterId) ? currentResource.clusterId : '',
      });
    };
    processCurrentResource(myFunc);
    setAllItems(itemList);
  };

  const getSelectedResources = (): Map<string, boolean> => {
    const resourceMap = new Map<string, boolean>();
    if (collectionProps.selectedItems) {
      for (let itemIndex = 0; itemIndex < collectionProps.selectedItems?.length; itemIndex += 1) {
        const item = collectionProps.selectedItems[itemIndex];
        resourceMap.set(item.type.concat(item.name), true);
      }
    }
    return resourceMap;
  };

  const sendItems = (): AwsService[] => {
    const resourceMap: Map<string, boolean> = getSelectedResources();
    const myFunc = (currentService, currentType, currentResource) => {
      const selection = resourceMap.get(currentType.type.concat(currentResource.name));
      currentResource.selected = selection;
    };
    processCurrentResource(myFunc);
    return listResources;
  };

  function matchesConstruct(item, selectedConstruct) {
    return selectedConstruct === defaultConstruct.value || item.supportedConstruct === selectedConstruct;
  }

  function matchesRelevant(item, selectedRelevant) {
    return selectedRelevant === defaultRelevantOption.value || item.relevant === (selectedRelevant === 'Yes');
  }

  const FullPageHeader = ({ ...props }) => (
    <TableHeader
      variant="awsui-h1-sticky"
      title="Discovered Resources"
      actionButtons={(
        <SpaceBetween size="s" direction="horizontal">
          <div data-testid="usc-spinner">
            {isProcessing === true ? <Spinner size="normal" /> : ''}
          </div>
        </SpaceBetween>
      )}
      {...props}
    />
  );

  function EmptyState({
    title,
    subtitle,
    action,
  }: EmptyStateProps) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(
    allItems,
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
        filteringFunction: (item, filteringText) => {
          if (!matchesConstruct(item, construct) || !matchesRelevant(item, relevant)) {
            return false;
          }
          const filteringTextLowerCase = filteringText.toLowerCase();

          return SEARCHABLE_COLUMNS.map((key) => item[key])
            .some(
              (value) => typeof value === 'string' && value.toLowerCase()
                .indexOf(filteringTextLowerCase) > -1,
            );
        },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: { keepSelection: true },
    },
  );

  // Custom RUM event is added here to count number of times we count the matches.
  // This serves as a proxy to count if user is using the search function as everytime
  // filtering is used this function will be called to count number of matches.
  const getFilterCounterText = (count) => {
    const rumClient = AwsRumClient.getRumClient();
    if (rumClient) {
      rumClient.recordRumEvent('SearchFilter', { count: 1 });
    }
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  };

  useEffect(() => {
    getItems();
  }, []);

  return (
    <SpaceBetween size="s">
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div style={{ flex: 1 }}>
          <b>{`Resources discovered from account '${accountLabel}' in region '${regionID}'`}</b>
        </div>
        <Button
          ariaLabel="Feedback"
          iconAlign="right"
          href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
          variant="link"
          iconName="external"
          target="_blank"
        >
          Provide Feedback
        </Button>
      </div>
      <Alert visible={isBlocked} type="error">
        {`${blockMsg}`}
      </Alert>
      {displayErrorMessage(errorMessages, 'Some resources from the above services may be missing, but feel free to proceed.')}
      <Container>
        <Header
          variant="h3"
          actions={
            (
              <Button
                variant="primary"
                iconAlign="right"
                disabled={isBlocked || collectionProps?.selectedItems?.length === 0}
                onClick={async (event) => {
                  setIsProcessing(true);
                  event.preventDefault();
                  let blocked = false;
                  let blockedMsg = '';
                  const accountId = accountLabel.split(':')[0];
                  const awsGenerationInput: AwsGenerationInput = {
                    awsServices: sendItems(),
                    outputFormat: OutputFormat.JSON,
                    options: {
                      awsAccountId: accountId,
                      awsRegion: regionID,
                      targetAwsAccountId: targetAccountID,
                      targetAwsRegion: targetRegion,
                      cdkConstructType: constructType,
                      useCase: selectedUseCase.value as UseCase,
                      stackGrouping: 'cluster',
                      outputType: 'ZIP',
                      // This parameter is currently not used in AlkimiaService
                      // TODO: Update to take in user input
                      projectName: '',
                    },
                  };
                  try {
                    const awsGenerationOutput = await userSelection(token, awsGenerationInput);
                    setAwsGenerationOutput(awsGenerationOutput);
                  } catch (e: any) {
                    blocked = true;
                    blockedMsg = 'Unable to send request to server. Please try again';
                  }
                  setIsProcessing(false);
                  if (blocked) {
                    setIsBlocked(true);
                    setBlockMsg(blockedMsg);
                  } else {
                    history.push('/output');
                  }
                }}
              >
                Generate CDK template
              </Button>
            )
          }
        >
          You can update Advanced Options to customize the generated CDK templates.
        </Header>

        <ExpandableSection
          headerText="Advanced Options"
          variant="navigation"
        >
          <SpaceBetween size="s" direction="horizontal">
            {FEATURE_USE_CASE_SELECTION[getStage()] && (
            <FormField
            description="This feature is under development"
              label="Use case"
            >
              <Select
                selectedOption={selectedUseCase as OptionDefinition}
                onChange={({ detail: { selectedOption } }) => {
                  setSelectedUseCase(selectedOption as SelectedOption);
                  disableTargetOptionsForImport(selectedOption);
                }}
                options={useCaseOptions}
                filteringType="auto"
              />
            </FormField>
            )}
            <FormField
              label="Target AWS account ID"
            >
              <Input
                disabled={targetDisbale}
                value={targetAccountID}
                onChange={(event) => settargetAccountID(event.detail.value)}
                placeholder="Enter export account ID"
              />
            </FormField>
            <FormField
              label="Target AWS region"
            >
              <Select
                disabled={targetDisbale}
                selectedOption={selectedTargetRegion as OptionDefinition}
                onChange={({ detail: { selectedOption } }) => {
                  setselectedTargetRegion(selectedOption as SelectedOption);
                  settargetRegion(selectedOption.value as string);
                }}
                options={regionOptions}
                filteringType="auto"
              />
            </FormField>
          </SpaceBetween>
        </ExpandableSection>
      </Container>

      <div id="select-table">
        <Table
          {...collectionProps}
          selectionType="multi"
          header={(
            <FullPageHeader
              selectedItems={collectionProps.selectedItems}
              totalItems={allItems}
            />
          )}
          variant="full-page"
          columnDefinitions={COLUMN_DEFINITIONS}
          visibleColumns={preferences.visibleContent}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          filter={
            (
              <div className="input-container">
                <SpaceBetween direction="horizontal" size="m">
                  <div className="text-filter">
                    <TextFilter
                      {...filterProps}
                      filteringAriaLabel="Filter resources"
                      filteringPlaceholder="Find resources"
                      countText={getFilterCounterText(filteredItemsCount)}
                    />
                  </div>
                  <div className="select-filter">
                    <Select
                      options={constructOptions}
                      selectedOption={selectedFilterConstruct}
                      onChange={({ detail: { selectedOption } }) => {
                        setSelectedFilterConstruct(selectedOption as SelectedOption);
                        setConstruct(selectedOption.value as string);
                      }}
                    />
                  </div>
                  <div className="select-filter">
                    <Select
                      options={relevantOptions}
                      selectedOption={selectedRelevantOption}
                      onChange={({ detail: { selectedOption } }) => {
                        setSelectedRelevantOption(selectedOption as SelectedOption);
                        setRelevant(selectedOption.value as string);
                      }}
                    />
                  </div>
                  <div>
                    {
                      (filterProps.filteringText || construct !== defaultConstruct.value
                        || relevant !== defaultRelevantOption.value)
                      && (
                        <span
                          className="filtering-results"
                        >
                          {getFilterCounterText(filteredItemsCount)}
                        </span>
                      )
                    }
                  </div>
                </SpaceBetween>
              </div>
            )
          }
          stickyHeader
          resizableColumns
          wrapLines={preferences.wrapLines}
          preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
        />
      </div>
    </SpaceBetween>
  );
}
