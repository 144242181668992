import { AlkimiaOptions, AwsResourceConfiguration, Feature, FeatureFlagManager } from '@amzn/alkimia-model';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Pagination,
  Popover,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { useEffect, useState } from 'react';
import { CONSTRUCT_OPTIONS, IGNORED_RESOURCE_TYPES, MANAGED_BY_CLOUDFORMATION_OPTIONS, RELEVANT_OPTIONS } from '../../../utils/Constants';
import { AwsRumClient } from '../../../utils/awsRum';
import { COLUMN_DEFINITIONS, DEFAULT_PREFERENCES, Preferences, SEARCHABLE_COLUMNS, TableHeader } from '../tableConfig';
import { CoverageAnalysisOverview } from './CoverageAnalysisOverview';
import { EmptyStateProps, ItemType, SelectedOption, defaultConstruct, defaultManagedByCloudFormation, defaultRelevantOption } from './Types';

export type AlkimiaResourceSelectionProps = {
  token: CognitoToken,
  tableItems: ItemType[],
  allTableItems: ItemType[],
  listResources: AwsResourceConfiguration[],
  selectedItems: any,
  isVerifiedResourcesToggle: boolean,
  alkimiaOptions: AlkimiaOptions,
  setClusters: React.Dispatch<React.SetStateAction<Map<string, ItemType[]>>>,
  setSortedListResources: React.Dispatch<React.SetStateAction<AwsResourceConfiguration[]>>,
  setTableItems: React.Dispatch<React.SetStateAction<any>>,
  setAllTableItems: React.Dispatch<React.SetStateAction<any>>,
  setSelectedItems: React.Dispatch<React.SetStateAction<any>>,
  setVerifiedResourcesToggle: React.Dispatch<React.SetStateAction<boolean>>,
  setClusteredListCheckState: React.Dispatch<React.SetStateAction<boolean[]>>,
}

// Custom RUM event is added here to count number of times we count the matches.
// This serves as a proxy to count if user is using the search function as everytime
// filtering is used this function will be called to count number of matches.
const getFilterCounterText = (count) => {
  const rumClient = AwsRumClient.getRumClient();
  if (rumClient) {
    rumClient.recordRumEvent('SearchFilter', { count: 1 });
  }
  return `${count} ${count === 1 ? 'match' : 'matches'}`;
};

/**
 * Child component of ResourceSelectionComponent that display data in a table
 * @param tableItems - items that will be displayed on the table
 * @param listResources - AwsResourceConfiguration[], basically the data we recieved to display
 * @returns TableComponent for ResourceSelection Page
 */
function ResourceSelectionComponent({
  token,
  allTableItems,
  tableItems,
  listResources,
  selectedItems,
  isVerifiedResourcesToggle,
  alkimiaOptions,
  setClusters,
  setSortedListResources,
  setTableItems,
  setAllTableItems,
  setSelectedItems,
  setClusteredListCheckState,
  setVerifiedResourcesToggle,
}: AlkimiaResourceSelectionProps) {
  const [construct, setConstruct] = useState('0');
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [awsService, setAwsService] = useState('0');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listOfAwsServicesOptions, setListOfAwsServicesOptions] = useState<SelectedOption[]>([{ label: 'All AWS Services', value: 'DEFAULT' }]);
  const [selectListOfAwsServiceOptions, setSelectListOfAwsServiceOptions] = useState<SelectedOption>(listOfAwsServicesOptions[0]);
  const [selectedFilterConstruct, setSelectedFilterConstruct] = useState<SelectedOption>(CONSTRUCT_OPTIONS[0]);
  const [selectedRelevantOption, setSelectedRelevantOption] = useState<SelectedOption>(RELEVANT_OPTIONS[0]);
  const [selectedManagedByCloudFormationOption, setSelectedManagedByCloudFormationOption] = useState<SelectedOption>(MANAGED_BY_CLOUDFORMATION_OPTIONS[0]);
  const [constructOptions, setConstructOptions] = useState<SelectedOption[]>(CONSTRUCT_OPTIONS);
  const [relevantOptions, setRelevantOptions] = useState<SelectedOption[]>(RELEVANT_OPTIONS);
  const [managedByCloudFormationOptions] = useState<SelectedOption[]>(MANAGED_BY_CLOUDFORMATION_OPTIONS);
  const [relevant, setRelevant] = useState('0');
  const [managedByCloudFormation, setManagedByCloudFormation] = useState<string>('0');

  const processCurrentResource = (funcUsedToProcess) => listResources.forEach((currentService) => {
    funcUsedToProcess(currentService);
  });

  const getVerifiedServicesToggleComponent = () => (
    <div>
      <SpaceBetween size="s" direction="horizontal">
        <Toggle
          id="verified-resource-toggle"
          onChange={({ detail }) => {
            setVerifiedResourcesToggle(detail.checked);
            if (detail.checked) {
              // Display verified services toggle temporarily shows L2 supported resources only.
              // We need a better mechanism going forward for verified services having both L1 and L2 coverage.
              setTableItems(allTableItems.filter((item) => item.verifiedByAlkimia && item.supportedConstruct === 'L2'));
            } else {
              setTableItems(allTableItems);
            }
          }}
          checked={isVerifiedResourcesToggle}
        >
          Display Verified Services
        </Toggle>
        <Popover
        dismissButton={false}
        position="left"
        size="small"
        triggerType="custom"
        content={(
          <p>
            Resources that have been verified to deliver 90% template completion and deployability.
          </p>
        )}
      >
          <Button iconName="status-info" variant="inline-icon" />
        </Popover>
      </SpaceBetween>
    </div>
  );

  const matchesConstruct = (item, selectedConstruct) => selectedConstruct === defaultConstruct.value || item.supportedConstruct === selectedConstruct;

  const matchesRelevant = (item, selectedRelevant) => selectedRelevant === defaultRelevantOption.value || item.relevant === (selectedRelevant === 'Yes');

  const matchesAwsService = (item, selectedAwsService) => (selectedAwsService === '0' || selectedAwsService === 'DEFAULT' || item.service === selectedAwsService);

  const matchesManagedByCloudFormation = (item, selectedManagedByCloudFormation) => selectedManagedByCloudFormation === defaultManagedByCloudFormation.value || item.managedByCfn === (selectedManagedByCloudFormation === 'Yes');

  const FullPageHeader = ({ ...props }) => (
    <TableHeader
      variant="awsui-h1-sticky"
      title="Discovered Resources"
      actionButtons={(
        <SpaceBetween size="s" direction="horizontal">
          <div data-testid="usc-spinner">
            {isProcessing === true ? <Spinner size="normal" /> : ''}
          </div>
          {FeatureFlagManager.getInstance().isFeatureFlagEnabled(alkimiaOptions, Feature.VERIFIED_SERVICES_TOGGLE) && getVerifiedServicesToggleComponent()}
        </SpaceBetween>
      )}
      {...props}
    />
  );

  const getItems = () => {
    const itemList: ItemType[] = [];
    const itemsWithNoClusters: ItemType[] = [];
    const clusterMap: Map<string, ItemType[]> = new Map([]); // key is the clusterId, val is the list of resources associated to clusterId
    const sortedList = listResources.sort((a, b) => a.resourceType.localeCompare(b.resourceType));

    // Create options object list based on aws services in account
    const awsServiceSet: Set<{ lable: string, value: string }> = new Set([]);
    const visitedAwsServices: Set<string> = new Set([]);
    listResources.forEach((service) => {
      const currAwsService = service.resourceType.split('::')[1];
      if (!visitedAwsServices.has(currAwsService)) {
        visitedAwsServices.add(currAwsService);
        awsServiceSet.add({
          lable: currAwsService,
          value: currAwsService,
        });
      }
    });
    setListOfAwsServicesOptions(listOfAwsServicesOptions.concat(Array.from(awsServiceSet.values()) as unknown as SelectedOption[]));
    setSortedListResources(sortedList);
    const myFunc = (currentConfiguration: AwsResourceConfiguration) => {
      const selectedPropertyExist = Object.prototype.hasOwnProperty.call(currentConfiguration, 'selected');
      let resourceName = '';
      // the currentConfiguration.ids.resourceName field can be overriden from the Alkimia Discovery logic to force a specific name from the discovered configuration.
      // Sample cr https://code.amazon.com/reviews/CR-150672536/revisions/1#/diff
      if (currentConfiguration.ids.resourceName && currentConfiguration.ids.resourceName !== '') {
        resourceName = currentConfiguration.ids.resourceName;
      } else if (currentConfiguration.ids.resourceId && currentConfiguration.ids.resourceId !== '') {
        resourceName = currentConfiguration.ids.resourceId;
      } else if (currentConfiguration.ids.arn && currentConfiguration.ids.arn !== '') {
        resourceName = currentConfiguration.ids.arn;
      }

      let resourceSelection = false;

      if (selectedPropertyExist) {
        resourceSelection = currentConfiguration.selected!;
      }

      const item: ItemType = {
        name: resourceName,
        selected: resourceSelection,
        type: currentConfiguration.resourceType,
        service: currentConfiguration.resourceType.split('::')[1],
        supportedConstruct: currentConfiguration.supportedConstructType!,
        relevant: currentConfiguration.relevant!,
        managedByCfn: currentConfiguration.managedByCfn!,
        clusterId: (currentConfiguration.clusterId) ? currentConfiguration.clusterId : '',
        verifiedByAlkimia: currentConfiguration.verifiedByAlkimia,
      };

      // Do not display any networking resources
      // Do not display resources that do not have
      // TODO update SDK query logic to disable scans for EC2 Networking resources
      if (!IGNORED_RESOURCE_TYPES.has(currentConfiguration.resourceType) && resourceName !== undefined && resourceName !== '') {
        itemList.push(item);

        if (currentConfiguration.clusterId) {
          if (clusterMap.has(currentConfiguration.clusterId)) {
            const clusterListOfItems = clusterMap.get(currentConfiguration.clusterId)!;
            clusterListOfItems.push(item);
            clusterMap.set(currentConfiguration.clusterId, clusterListOfItems);
          } else {
            clusterMap.set(currentConfiguration.clusterId, [item]);
          }
        } else {
          itemsWithNoClusters.push(item);
        }
      }
    };
    processCurrentResource(myFunc);
    // Display verified services toggle temporarily shows L2 supported resources only.
    // We need a better mechanism going forward for verified services having both L1 and L2 coverage.
    setTableItems(itemList.filter((item) => item.verifiedByAlkimia && item.supportedConstruct === 'L2'));
    setAllTableItems(itemList);
    const sortedClusterList = [...clusterMap.entries()].sort((a, b) => Number(a[0]) - Number(b[0]));
    sortedClusterList.push(['resourcesWithNoCluster', itemsWithNoClusters]); // create another cluster that contains items with no clusters
    setClusters(new Map(sortedClusterList)); // Sort based on ClusterId
    const checkedFalseItems: boolean[] = new Array<boolean>(sortedClusterList.length);
    checkedFalseItems.fill(false);
    setClusteredListCheckState(checkedFalseItems);
  };

  function EmptyState({
    title,
    subtitle,
    action,
  }: EmptyStateProps) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  useEffect(() => {
    getItems();
  }, []);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(
    tableItems,
    {
      filtering: {
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
        filteringFunction: (item, filteringText) => {
          if (!matchesConstruct(item, construct) || !matchesRelevant(item, relevant)
            || !matchesAwsService(item, awsService) || !matchesManagedByCloudFormation(item, managedByCloudFormation)) {
            return false;
          }
          const filteringTextLowerCase = filteringText.toLowerCase();

          return SEARCHABLE_COLUMNS.map((key) => item[key])
            .some(
              (value) => typeof value === 'string' && value.toLowerCase()
                .indexOf(filteringTextLowerCase) > -1,
            );
        },
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: { keepSelection: true },
    },
  );

  return (
    <>
      <div id="select-table">
        <Table
          {...collectionProps}
          contentDensity="compact"
          selectionType="multi"
          header={(
            <>
              <FullPageHeader
                selectedItems={collectionProps.selectedItems}
                totalItems={tableItems}
                isProcessing={isProcessing}
              />
              {FeatureFlagManager.getInstance().isFeatureFlagEnabled(alkimiaOptions, Feature.IAC_COVERAGE_ANALYSIS) && <CoverageAnalysisOverview
                token={token}
                resourceConfigurations={listResources}
              />}
            </>
          )}
          selectedItems={selectedItems}
          variant="full-page"
          columnDefinitions={COLUMN_DEFINITIONS}
          visibleColumns={preferences.visibleContent}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          filter={(
            <div className="input-container">
              <SpaceBetween direction="horizontal" size="m">
                <div className="text-filter">
                  <TextFilter
                    {...filterProps}
                    filteringAriaLabel="Filter resources"
                    filteringPlaceholder="Find resources"
                    countText={getFilterCounterText(filteredItemsCount)}
                  />
                </div>
                <div className="select-filter">
                  <Select
                    options={listOfAwsServicesOptions}
                    selectedOption={selectListOfAwsServiceOptions}
                    onChange={({ detail: { selectedOption } }) => {
                      setSelectListOfAwsServiceOptions(selectedOption as SelectedOption);
                      setAwsService(selectedOption.value as string);
                    }}
                  />
                </div>
                <div className="select-filter">
                  <Select
                    options={constructOptions}
                    selectedOption={selectedFilterConstruct}
                    onChange={({ detail: { selectedOption } }) => {
                      setSelectedFilterConstruct(selectedOption as SelectedOption);
                      setConstruct(selectedOption.value as string);
                    }}
                  />
                </div>
                <div className="select-filter">
                  <Select
                    options={relevantOptions}
                    selectedOption={selectedRelevantOption}
                    onChange={({ detail: { selectedOption } }) => {
                      setSelectedRelevantOption(selectedOption as SelectedOption);
                      setRelevant(selectedOption.value as string);
                    }}
                  />
                </div>
                <div className="select-filter">
                  <Select
                    options={managedByCloudFormationOptions}
                    selectedOption={selectedManagedByCloudFormationOption}
                    onChange={({ detail: { selectedOption } }) => {
                      setSelectedManagedByCloudFormationOption(selectedOption as SelectedOption);
                      setManagedByCloudFormation(selectedOption.value as string);
                    }}
                  />
                </div>
                <div>
                  {
                    (filterProps.filteringText
                      || construct !== defaultConstruct.value
                      || relevant !== defaultRelevantOption.value
                      || awsService !== 'DEFAULT'
                      || managedByCloudFormation !== defaultManagedByCloudFormation.value)
                    && (
                      <span
                        className="filtering-results"
                      >
                        {getFilterCounterText(filteredItemsCount)}
                      </span>
                    )
                  }
                </div>
              </SpaceBetween>
            </div>
          )}
          stickyHeader
          resizableColumns
          wrapLines={preferences.wrapLines}
          preferences={<Preferences preferences={preferences} setPreferences={setPreferences} />}
          onSelectionChange={(event) => { setSelectedItems(event.detail.selectedItems); }}
        />
      </div>
    </>
  );
}

export const AlkimiaTableComponent = ResourceSelectionComponent;
