export enum DeploymentStage {
  LOCAL = 'LOCAL',
  BETA = 'BETA',
  GAMMA = 'GAMMA',
  PROD = 'PROD',
}

export const SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS = 900;

export const API_TIMEOUT_INTERVAL_IN_MS = process.env.REACT_APP_LAMBDA_API_URL ? 15000 : 4000;
export const API_RETRY_INTERVAL_IN_MS = API_TIMEOUT_INTERVAL_IN_MS + 2000;

export const LAMBDA_API_URL = {
  // Set REACT_APP_LAMBDA_API_URL to http://localhost:3004 to use the local REST API server started with the `bb start-alk-proxy-api` script.
  [DeploymentStage.LOCAL]: process.env.REACT_APP_LAMBDA_API_URL || 'https://api.beta.alkimia.tools.amazon.dev/prod',
  [DeploymentStage.BETA]: 'https://api.beta.alkimia.tools.amazon.dev/prod',
  [DeploymentStage.GAMMA]: 'https://api.gamma.alkimia.tools.amazon.dev/prod',
  [DeploymentStage.PROD]: 'https://api.prod.alkimia.tools.amazon.dev/prod',
};

export const CALL_BACK_URL = {
  [DeploymentStage.LOCAL]: 'http://localhost:3000',
  [DeploymentStage.BETA]: 'https://beta.alkimia.tools.amazon.dev',
  [DeploymentStage.GAMMA]: 'https://gamma.alkimia.tools.amazon.dev',
  [DeploymentStage.PROD]: 'https://prod.alkimia.tools.amazon.dev',
};

export const COGNITO_WEB_DOMAIN = {
  [DeploymentStage.LOCAL]: 'alkimia-dev-user-pool.auth.us-east-1.amazoncognito.com',
  [DeploymentStage.BETA]: 'alkimia-dev-user-pool.auth.us-east-1.amazoncognito.com',
  [DeploymentStage.GAMMA]: 'alkimia-dev-gamma-user-pool.auth.us-east-1.amazoncognito.com',
  [DeploymentStage.PROD]: 'alkimia-dev-prod-user-pool.auth.us-east-1.amazoncognito.com',
};

export const COGNITO_USER_POOL_ID = {
  [DeploymentStage.LOCAL]: 'us-east-1_vjdmLseAS',
  [DeploymentStage.BETA]: 'us-east-1_vjdmLseAS',
  [DeploymentStage.GAMMA]: 'us-east-1_ACfCC7yCO',
  [DeploymentStage.PROD]: 'us-east-1_iRQBLeYdT',
};

export const COGNITO_CLIENT_ID = {
  [DeploymentStage.LOCAL]: '3hjvnt3hiajq7qage7duqv6hbq',
  [DeploymentStage.BETA]: '3hjvnt3hiajq7qage7duqv6hbq',
  [DeploymentStage.GAMMA]: '3h7q08lgood5gh62g5v8qjmtds',
  [DeploymentStage.PROD]: '29a2lioqji9v552d8oa2h9m0s7',
};

export const AWS_RUM_GUEST_ROLE_ARN = {
  [DeploymentStage.LOCAL]: 'arn:aws:iam::155514714706:role/RUM-Monitor-us-east-1-155514714706-5916317171071-Unauth',
  [DeploymentStage.BETA]: 'arn:aws:iam::155514714706:role/RUM-Monitor-us-east-1-155514714706-4556245271071-Unauth',
};

export const AWS_RUM_IDENTITY_POOL_ID = {
  [DeploymentStage.LOCAL]: 'us-east-1:41f83eee-088c-40cd-9b15-12509c0e5624',
  [DeploymentStage.BETA]: 'us-east-1:c22e1d97-6b77-403d-b1dd-7537616f3599',
};

export const AWS_RUM_ENDPOINT = {
  [DeploymentStage.LOCAL]: 'https://dataplane.rum.us-east-1.amazonaws.com',
  [DeploymentStage.BETA]: 'https://dataplane.rum.us-east-1.amazonaws.com',
};

export const AWS_RUM_APPLICATION_ID = {
  [DeploymentStage.LOCAL]: '22b22054-a5cb-4ff8-8316-c33cbf07040c',
  [DeploymentStage.BETA]: '25b0f213-be67-45e2-b194-336b62818d16',
};

export const LIST_OF_REGIONS = [
  { label: 'us-east-1 (N.Virginia)', value: 'us-east-1' },
  { label: 'us-east-2 (Ohio)', value: 'us-east-2' },
  { label: 'us-west-1 (N.California)', value: 'us-west-1' },
  { label: 'us-west-2 (Oregon)', value: 'us-west-2' },
  { label: 'ap-south-1 (Mumbai)', value: 'ap-south-1' },
  { label: 'ap-northeast-1 (Tokyo)', value: 'ap-northeast-1' },
  { label: 'ap-northeast-2 (Seoul)', value: 'ap-northeast-2' },
  { label: 'ap-northeast-3 (Osaka)', value: 'ap-northeast-3' },
  { label: 'ap-southeast-1 (Tokyo)', value: 'ap-southeast-1' },
  { label: 'ap-southeast-2 (Sydney)', value: 'ap-southeast-2' },
  { label: 'ca-central-1 (Canada Central)', value: 'ca-central-1' },
  { label: 'eu-central-1 (Frankfurt)', value: 'eu-central-1' },
  { label: 'eu-west-1 (Ireland)', value: 'eu-west-1' },
  { label: 'eu-west-2 (London)', value: 'eu-west-2' },
  { label: 'eu-west-3 (Paris)', value: 'eu-west-3' },
  { label: 'eu-north-1 (Stockholm)', value: 'eu-north-1' },
  { label: 'eu-south-2 (Spain)', value: 'eu-south-2' },
  { label: 'sa-east-1 (Sao Paulo)', value: 'sa-east-1' },
];

export const CONSTRUCT_OPTIONS = [
  { label: 'Any Construct type supported', value: '0' },
  { label: 'Only L1 Construct supported', value: 'L1' },
  { label: 'L2 Construct supported', value: 'L2' },
];

export const RELEVANT_OPTIONS = [
  { label: 'User Created + Default Resources', value: '0' },
  { label: 'User Created', value: 'Yes' },
  { label: 'Default Only', value: 'No' },
];

export const MANAGED_BY_CLOUDFORMATION_OPTIONS = [
  { label: 'Managed by CloudFormation + Not Managed by CloudFormation', value: '0' },
  { label: 'Managed by CloudFormation', value: 'Yes' },
  { label: 'Not Managed by CloudFormation', value: 'No' },
];

export const MAX_CLUSTER_NUMBER = 9999;

export const IGNORED_RESOURCE_TYPES = new Set([
  'AWS::EC2::EIP',
  'AWS::EC2::FlowLog',
  'AWS::EC2::InternetGateway',
  'AWS::EC2::NatGateway',
  'AWS::EC2::NetworkAcl',
  'AWS::EC2::NetworkInsightsAccessScope',
  'AWS::EC2::NetworkInsightsAccessScopeAnalysis',
  'AWS::EC2::NetworkInterface',
  'AWS::EC2::PrefixList',
  'AWS::EC2::Route',
  'AWS::EC2::RouteTable',
  'AWS::EC2::Subnet',
  'AWS::EC2::SubnetNetworkAclAssociation',
  'AWS::EC2::SubnetRouteTableAssociation',
  'AWS::EC2::VPC',
  'AWS::EC2::VPCCidrBlock',
  'AWS::EC2::VPCDHCPOptionsAssociation',
  'AWS::EC2::VPCEndpoint',
  'AWS::EC2::VPCEndpointConnectionNotification',
  'AWS::EC2::VPCEndpointService',
  'AWS::EC2::VPCEndpointServicePermissions',
  'AWS::EC2::VPCGatewayAttachment',
  'AWS::EC2::VPCPeeringConnection',
  'AWS::EC2::VPNConnection',
  'AWS::EC2::VPNConnectionRoute',
  'AWS::EC2::VPNGateway',
  'AWS::EC2::VPNGatewayRoutePropagation',
]);// scanAwsAccount Lambda function can take up to 900 seconds.  What value will a user tolerate in a UI?
